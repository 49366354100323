import React from "react";

export default function Card({ title, content, picture, totalSupply }) {
  return (
    <div
      className="flex flex-col gap-[2] p-[2rem] rounded-[1rem] justify-center max-w-[20rem] items-center border"
      style={{
        boxShadow: "0 0 0 0 white, 0 0 10px white",
        transition: "box-shadow 0.3s ease",
      }}
    >
      <h4 className="text-white">{title}</h4>
      <p className="text-white text-xs text-center">{content}</p>
      <p className="text-white text-xs text-center">
        total Supply : {totalSupply}
        <img src={picture} alt="" className="mt-2 rounded-lg" />
      </p>
    </div>
  );
}
