import React, { useContext } from "react";
import AddCandidate from "../forms/AddCandidate";
import Listcandidates from "../gallery/Listcandidates";
import Votechart from "../charts/Votechart";
import { CandidateContext } from "../../contexts/candidateContext";

//contract address 0xEcDE284BE7d300BDD6790fD0BD77A6bd3A22F336 on goerli testnet

const Project3 = () => {
  const { state, candData, setCandData } = useContext(CandidateContext);

  return (
    <div className="text-white flex flex-col items-center w-full py-10 text-3xl justify-center">
      <p className="text-center">
        Project 3: Blockchain Voting app on Goerli testnet
      </p>
      <AddCandidate
        state={state}
        candData={candData}
        setCandData={setCandData}
      />
      <Listcandidates state={state} />
      <Votechart state={state} />
      {/* Grey line */}
      <div className="w-full px-5 bg-gray-400 h-[0.25px] mt-1" />
    </div>
  );
};

export default Project3;
