import React, { useState } from "react";
import TextInput from "../ui/TextInput";
import Card from "../ui/Card";
import { Alchemy, Network } from "alchemy-sdk";
import Loader from "../Loader/Loader";

const Project1 = () => {
  const [userAddress, setUserAddress] = useState("");
  const [tokenDataObjects, setTokenDataObjects] = useState([]);
  const [results, setResults] = useState([]);
  const [loadingInProgress, setLoading] = useState(false);
  const [hasQueried, setHasQueried] = useState(false);

  const getNftsForOwner = async () => {
    if (!userAddress) {
      return window.alert("Please input user address");
    }

    const config = {
      apiKey: process.env.REACT_APP_ALCHEMY_API,
      network: Network.ETH_MAINNET,
    };

    const alchemy = new Alchemy(config);

    // Check if address is an ENS
    try {
      const address = await alchemy.core.resolveName(userAddress);
      console.log(address);
    } catch (err) {
      console.log(err);
    }

    // Active loading
    setLoading(true);
    const data = await alchemy.nft.getNftsForOwner(userAddress);
    setResults(data);

    const tokenDataPromises = [];

    for (let i = 0; i < data.ownedNfts.length; i++) {
      const tokenData = alchemy.nft.getNftMetadata(
        data.ownedNfts[i].contract.address,
        data.ownedNfts[i].tokenId
      );
      tokenDataPromises.push(tokenData);
    }

    console.log("results", results);
    setTokenDataObjects(await Promise.all(tokenDataPromises));
    console.log(tokenDataObjects);

    setHasQueried(true);
    // Remove loading
    setLoading(false);
  };
  return (
    <>
      {/* Project 1*/}
      <div className="text-white flex flex-col items-center w-full py-10 text-3xl">
        <p className="text-center">
          Project 1 : NFT Explorer on Ethereum Mainnet
        </p>
        {/* text input for user to put in their address to get their nfts*/}
        <div className="w-1/3 flex items-center gap-5 py-4 border-b border-[#66666645]">
          <div className="flex-1 relative">
            <TextInput
              styles="w-full rounded-full py-5 pr-14" // Adjust padding for the input and button spacing
              placeholder="Type in your Ethereum address or your ENS...."
              type="text"
              name="address"
              required=""
              onChange={(e) => setUserAddress(e.target.value)}
            />
            <button
              type="button"
              onClick={getNftsForOwner}
              className="absolute right-3 top-6 h-15 w-10 flex items-center justify-center text-[#808080] p-1 rounded-full cursor-pointer hover:bg-[#dadfee]"
            >
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </button>
          </div>
        </div>
        {loadingInProgress ? (
          <Loader />
        ) : (
          <div className="w-[0.5] justify-between grid lg:grid-cols-5 md:grid-col-3 sm: grid-cols-1 gap-4 p-10 items-center">
            {results?.ownedNfts?.length > 0 && hasQueried ? (
              results?.ownedNfts?.map((e, i) => (
                <Card
                  key={i}
                  title={tokenDataObjects[i]?.name?.replace(
                    `#${tokenDataObjects[i]?.tokenId}`,
                    ""
                  )}
                  content={`${e.description?.slice(0, 30)}` + "..."}
                  picture={tokenDataObjects[i]?.image.thumbnailUrl}
                  totalSupply={e.contract.totalSupply}
                />
              ))
            ) : (
              <p className="text-white text-sm items-center text-center w-full lg:col-span-5 md:col-span-3">
                Media not found
              </p>
            )}
          </div>
        )}

        {/* Summary of your nfts */}
        {/* Grey line */}
        <div className="w-full bg-gray-400 h-[0.25px] mt-4" />
      </div>
    </>
  );
};

export default Project1;
