import { Goerli } from "@usedapp/core";

export const ROUTER_ADDRESS = "0x10Dc084ED68e8366ba7202b2B11e418d10EAaE43";

export const DAPP_CONFIG = {
  readOnlyChainId: Goerli.chainId,
  readOnlyUrls: {
    [Goerli.chainId]:
      "https://eth-goerli.g.alchemy.com/v2/EFce1P0ZNSRjLIH5saamQ5tiJak9P59d",
  },
};
