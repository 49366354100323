import React, { useState, useEffect } from "react";
import logo from "../assets/logo.jpg";
import { AiFillPlayCircle } from "react-icons/ai";
import { IoMdMenu } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import { shortenAddress } from "../utils/shortenAddress";
import { ethers } from "ethers";

/*Completed the responsive navbar layout consisting of a left-aligned logo and 
a right-aligned section containing icons for GitHub and LinkedIn (hidden on medium screens)
along with a 'Connect Wallet' button that triggers a function when clicked. 
The layout is designed to adjust across screen sizes while maintaining consistent spacing 
and alignment*/

const NavBar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [userAddress, setUserAddress] = useState("");
  const [isWalletConnected, setIsWalletConnected] = useState(
    JSON.parse(localStorage.getItem("isWalletConnected"))
  );

  const NavBarItem = ({ title, classprops }) => (
    <li className={`mx-4 cursor-pointer ${classprops}`}>{title}</li>
  );

  const connectWallet = async () => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      setUserAddress(address);
      localStorage.setItem("isWalletConnected", true);
      setIsWalletConnected(true);
    } else {
      window.alert("Please install Metamask");
    }
  };

  useEffect(() => {
    if (isWalletConnected) {
      connectWallet();
    }
  }, []);

  return (
    <>
      <div className="sticky top-0 ">
        <div className=" w-full flex md:justify-center justify-between items-center p-4 ">
          {/* left */}
          <div className="md:flex-[0.9] flex-initial justify-center items-center px-5">
            <img src={logo} alt="" className="w-14 h-14 rounded-full" />
          </div>
          {/* center*/}
          <div className="items-center pr-10">
            <ul className="text-white md:flex hidden list-none flex-row justify-between items-center flex-initial">
              {["Tutorials", "BuyMeACoffee"].map((item, index) => (
                <NavBarItem key={item + index} title={item} />
              ))}
            </ul>
          </div>
          {/* right */}
          <div className="flex gap-10 items-center text-ascent-1 text-md md:text-xl">
            {/* GitHub Logo */}
            <div className="hidden md:block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="40"
                width="40"
                viewBox="0 0 480 512"
              >
                <path
                  d="M186.1 328.7c0 20.9-10.9 55.1-36.7 55.1s-36.7-34.2-36.7-55.1 10.9-55.1 36.7-55.1 36.7 34.2 36.7 55.1zM480 278.2c0 31.9-3.2 65.7-17.5 95-37.9 76.6-142.1 74.8-216.7 74.8-75.8 0-186.2 2.7-225.6-74.8-14.6-29-20.2-63.1-20.2-95 0-41.9 13.9-81.5 41.5-113.6-5.2-15.8-7.7-32.4-7.7-48.8 0-21.5 4.9-32.3 14.6-51.8 45.3 0 74.3 9 108.8 36 29-6.9 58.8-10 88.7-10 27 0 54.2 2.9 80.4 9.2 34-26.7 63-35.2 107.8-35.2 9.8 19.5 14.6 30.3 14.6 51.8 0 16.4-2.6 32.7-7.7 48.2 27.5 32.4 39 72.3 39 114.2zm-64.3 50.5c0-43.9-26.7-82.6-73.5-82.6-18.9 0-37 3.4-56 6-14.9 2.3-29.8 3.2-45.1 3.2-15.2 0-30.1-.9-45.1-3.2-18.7-2.6-37-6-56-6-46.8 0-73.5 38.7-73.5 82.6 0 87.8 80.4 101.3 150.4 101.3h48.2c70.3 0 150.6-13.4 150.6-101.3zm-82.6-55.1c-25.8 0-36.7 34.2-36.7 55.1s10.9 55.1 36.7 55.1 36.7-34.2 36.7-55.1-10.9-55.1-36.7-55.1z"
                  fill="white"
                />
              </svg>
            </div>
            {/* LinkedIn Logo */}
            <div className="hidden md:block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="40"
                width="40"
                viewBox="0 0 448 512"
              >
                <path
                  d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                  fill="white"
                />
              </svg>
            </div>
            {/* Menu Bar */}
            <div>
              {toggleMenu ? (
                <AiOutlineClose
                  className="text-white h-9 w-9 md:hidden cursor-pointer"
                  onClick={() => setToggleMenu(false)}
                />
              ) : (
                <IoMdMenu
                  className="text-white cursor-pointer md:hidden h-9 w-9"
                  onClick={() => {
                    setToggleMenu(true);
                  }}
                />
              )}
              {toggleMenu && (
                <ul
                  className="z-10 fixed -top-0 -right-2 p-3 w-[70vw] h-screen shadow-2xl md:hidden list-none
            flex flex-col justify-start items-end rounded-md blue-glassmorphism
             text-white animate-slide-in"
                >
                  <li className="text-xl w-full my-2">
                    <AiOutlineClose onClick={() => setToggleMenu(false)} />
                  </li>
                  {["Tutorials", "BuyMeACoffee"].map((item, index) => (
                    <NavBarItem
                      key={item + index}
                      title={item}
                      classprops="my-2 text-lg"
                    />
                  ))}
                </ul>
              )}
            </div>
            <div>
              {!userAddress ? (
                <button
                  type="button"
                  onClick={connectWallet}
                  className="flex flex-row justify-center items-center bg-[#2952e3] p-1 rounded-full cursor-pointer hover:bg-[#87a0fa]"
                >
                  <AiFillPlayCircle className="text-white mr-2" />
                  <p className="text-white text-base font-semibold p-2">
                    Connect Wallet
                  </p>
                </button>
              ) : (
                <button
                  type="button"
                  onClick={connectWallet}
                  className="flex flex-row justify-center items-center bg-[#2952e3] p-1 rounded-full cursor-pointer hover:bg-[#87a0fa]"
                >
                  <p className="text-white text-base font-semibold p-2">
                    Connected Wallet to &nbsp;{" "}
                    <span>{shortenAddress(userAddress)}</span>
                  </p>
                </button>
              )}
            </div>
          </div>
        </div>
        {/* Grey line */}
        <div className="w-full px-5 bg-gray-400 h-[0.25px] mt-1" />
      </div>
    </>
  );
};

export default NavBar;
