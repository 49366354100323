import React, { useState, useRef, useEffect } from "react";
import { DownOutlined } from "@ant-design/icons";
import { useOnClickOutside } from "../../utils";

const AmountIn = ({
  value,
  onChange,
  currencyValue,
  onSelect,
  currencies,
  isSwapping,
}) => {
  // const tokenList = [
  //   { token: "TGD", tokenName: "TGD" },
  //   { token: "TKV", tokenName: "TKV" },
  //   { token: "SHIBTK", tokenName: "SHIBTK" },
  // ]; //placeholder to be replaced with currencies
  const [activeCurrency, setActiveCurrency] = useState("Select");
  const [showList, setShowList] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setShowList(false));

  //recall the value whenever currencies change or currencyValue changes
  //check if the currencies include that is currently selected, then setActiveCurrency to the currencyValue. Else just reset to "Select"
  useEffect(() => {
    if (Object.keys(currencies).includes(currencyValue)) {
      setActiveCurrency(currencies[currencyValue]);
    } else {
      setActiveCurrency("Select");
    }
  }, [currencies, currencyValue]);

  const currencyList = Object.entries(currencies);

  return (
    <div className="flex justify-between items-center flex-row w-full min-w-full bg-[#ffffff05] border-[1px] border-transparent hover:border-site-dim2 min-h-[96px] sm:p-8 p-4 rounded-[20px]">
      <input
        placeholder="0.0"
        type="number"
        value={value}
        disabled={isSwapping} //disable input only if we are swapping
        onChange={(e) =>
          typeof onChange === "function" && onChange(e.target.value)
        } //check if the type of onChange is a function.
        className="w-full flex-1 bg-transparent outline-none font-poppins font-black text-2xl text-white"
      />
      <button
        className="flex flex-row items-center bg-[#ffffff21] py-2 px-4 rounded-xl font-poppins font-bold text-white"
        onClick={() => setShowList((prevState) => !prevState)}
      >
        {activeCurrency}
        &nbsp;
        <DownOutlined className={showList ? "rotate-180" : "rotate-0"} />
      </button>
      {showList && (
        <ul
          ref={ref}
          className="absolute z-10 right-0 bg-[#1F1D2B] border-[1px] border-bg-[#ffffff21] mt-2 rounded-lg min-w-[170px] overflow-hidden"
        >
          {currencyList.map(([token, tokenName], index) => (
            <li
              key={index}
              className={`font-poppins font-medium text-base text-white px-5 py-3 ${
                activeCurrency === tokenName ? "bg-[#ffffff21]" : ""
              } cursor pointer`}
              onClick={() => {
                if (typeof onSelect === "function") onSelect(token);
                setActiveCurrency(tokenName);
                setShowList(false);
              }}
            >
              {tokenName}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AmountIn;
