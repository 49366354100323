import factoryAbi from "../contractJson/uniswapabis/factory.json";
import { getPairsInfo } from "./getPairsInfo";
import { ethers } from "ethers";

export const getFactoryInfo = async (factoryAddress, provider) => {
  const factory = new ethers.Contract(factoryAddress, factoryAbi, provider);

  const factoryInfo = {
    fee: await factory.feeTo(),
    feeToSetter: await factory.feeToSetter(),
    allPairsLength: await factory.allPairsLength(),
    allPairs: [],
  };

  //get Liquidity pairs
  for (let i = 0; i < factoryInfo.allPairsLength; i++) {
    factoryInfo.allPairs[i] = await factory.allPairs(i);
  }

  factoryInfo.pairsInfo = await getPairsInfo(factoryInfo.allPairs, provider);

  return factoryInfo;
};
