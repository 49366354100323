import TextInput from "../ui/TextInput";
import React, { useState } from "react";
import Loader from "../Loader/Loader";

const AddCandidate = ({ state, candData, setCandData }) => {
  const [success, setSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    //Connect to metamask wallet
    event.preventDefault();
    try {
      //get contract details
      const { contract } = state;

      //send request to contract
      setLoading(true);
      const tx = await contract.addCandidate(candData.name);
      await tx.wait();
      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        window.location.reload();
      }, "10 seconds");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="flex flex-col text-center mt-5 border border-[#808080] justify-center p-4 rounded-lg mb-5"
      style={{
        boxShadow: "0 0 0 0 white, 0 0 10px white",
        transition: "box-shadow 0.3s ease",
      }}
    >
      <h3 className="text-sm">Step 1: Add Candidate</h3>

      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-4 justify-center items-center"
      >
        <TextInput
          styles="w-full rounded-full py-5 pr-14" // Adjust padding for the input and button spacing
          placeholder="name"
          type="text"
          name="name"
          required=""
          onChange={(e) => setCandData({ ...candData, name: e.target.value })}
        />
        <div className="gap-4">
          {isLoading ? (
            <Loader />
          ) : (
            <button
              type="submit"
              className="flex flex-row justify-center items-center bg-[#2952e3] p-1 rounded-full cursor-pointer hover:bg-[#87a0fa]"
            >
              <p className="text-white text-base font-semibold p-2">
                Add candidate
              </p>
            </button>
          )}
        </div>
        {success ? (
          <p className="text-[#14A44D] text-base">
            Successfully added candidate
          </p>
        ) : null}
      </form>
    </div>
  );
};
export default AddCandidate;
