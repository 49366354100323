import React, { useState, useEffect, createContext } from "react";
import abi from "../contractJson/voting.json";
import { ethers } from "ethers";

export const CandidateContext = createContext({
  state: {},
  candData: [],
  setCandData: () => {},
});

export const CandidateContextProvider = ({ children }) => {
  const [candData, setCandData] = useState([
    {
      name: "",
    },
  ]);

  const [state, setState] = useState({
    provider: null,
    signer: null,
    contract: null,
  });
  // const [account, setAccount] = useState("Not connected");
  useEffect(() => {
    const template = async () => {
      const contractAddress = "0xEcDE284BE7d300BDD6790fD0BD77A6bd3A22F336";
      const contractABI = abi.abi;
      //Metamask part
      //1. In order do transactions on goerli testnet
      //2. Metmask consists of infura api which actually help in connecting to the blockchain
      try {
        const { ethereum } = window;
        // const account = await ethereum.request({
        //   method: "eth_requestAccounts",
        // });

        window.ethereum.on("accountsChanged", () => {
          window.location.reload();
        });
        // setAccount(account);
        const provider = new ethers.providers.Web3Provider(ethereum); //read the Blockchain
        const signer = provider.getSigner(); //write the blockchain

        const contract = new ethers.Contract(
          contractAddress,
          contractABI,
          signer
        );
        // console.log(contract);
        setState({ provider, signer, contract });
      } catch (error) {
        console.log(error);
      }
    };
    template();
  }, []);

  return (
    <CandidateContext.Provider
      value={{
        state,
        candData,
        setCandData,
      }}
    >
      {children}
    </CandidateContext.Provider>
  );
};
