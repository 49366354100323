import React from "react";
import ethereumLogo from "../../assets/ethereumLogo.png";

const Loader1 = ({ title }) => {
  return (
    <div className="flex justify-center items-center flex-col w-full min-h-full">
      <img
        src={ethereumLogo}
        alt="ethereum logo"
        className="w-56 h-56 object-contain"
      />
      <p className="font-poppins font-normal text-[#E2E2E2] text-lg text-center mt-10">
        {title}
      </p>
    </div>
  );
};

export default Loader1;
