import { useEffect } from "react";
import abi from "../contractJson/uniswapabis/router02.json";
import { ROUTER_ADDRESS } from "../config";
import { parseUnits } from "ethers/lib/utils";
import { useCall } from "@usedapp/core";
import { ethers } from "ethers";

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

//returns a key-value pair -> prev where the key is the tokenAddress and the name is the key-value
export const getAvailableTokens = (pools) =>
  pools.reduce((prev, curr) => {
    prev[curr.token0Address] = curr.token0Name;
    prev[curr.token1Address] = curr.token1Name;
    return prev;
  }, {});

//check for operation State
export const isOperationPending = (operationState) =>
  operationState.status === "PendingSignature" ||
  operationState.status === "Mining";

export const isOperationFailed = (operationState) =>
  operationState.status === "Fail" || operationState.status === "Exception";

export const isOperationSucceeded = (operationState) =>
  operationState.status === "Success";

export const findPoolByTokens = (pools, fromToken, toToken) => {
  if (!Array.isArray(pools) || !fromToken || !toToken) return undefined;

  return pools.find(
    (cur) =>
      (cur.token0Address === fromToken && cur.token1Address === toToken) ||
      (cur.token1Address === fromToken && cur.token0Address === toToken)
  );
};

export const getCounterpartTokens = (pools, fromToken) =>
  pools
    .filter(
      (cur) =>
        cur.token0Address === fromToken || cur.token1Address === fromToken
    )
    .reduce((prev, curr) => {
      if (curr.token0Address === fromToken) {
        prev[curr.token1Address] = curr.token1Name;
      } else if (curr.token1Address === fromToken) {
        prev[curr.token0Address] = curr.token0Name;
      }
      return prev;
    }, {});

export const useAmountsOut = (pairAddress, amountIn, fromToken, toToken) => {
  const isValidAmountIn = amountIn.gt(parseUnits("0"));
  const areParamsValid = !!(
    pairAddress &&
    isValidAmountIn &&
    fromToken &&
    toToken
  );

  const { ethereum } = window;
  const provider = new ethers.providers.Web3Provider(ethereum); //read the Blockchain
  const signer = provider.getSigner(); //write the blockchain
  const routerContract = new ethers.Contract(ROUTER_ADDRESS, abi, signer);

  const { error, value } =
    useCall(
      areParamsValid && {
        contract: routerContract,
        method: "getAmountsOut",
        args: [amountIn, [fromToken, toToken]],
      }
    ) ?? {};

  console.log("amounts", value);
  return error ? parseUnits("0") : value?.amounts[1];
};

export const getSuccessMessage = (swapApproveState, swapExecuteState) => {
  if (
    isOperationPending(swapExecuteState) ||
    isOperationPending(swapApproveState)
  ) {
    return undefined;
  }

  if (isOperationSucceeded(swapExecuteState)) {
    return "Swap executed successfully";
  }

  if (isOperationSucceeded(swapApproveState)) {
    return "Approval successful";
  }

  return undefined;
};

export const getFailureMessage = (swapApproveState, swapExecuteState) => {
  if (
    isOperationPending(swapApproveState) ||
    isOperationPending(swapExecuteState)
  ) {
    return undefined;
  }

  if (isOperationFailed(swapApproveState)) {
    return "Approval failed - " + swapApproveState.errorMessage;
  }

  if (isOperationFailed(swapExecuteState)) {
    return "Swap failed - " + swapExecuteState.errorMessage;
  }

  return undefined;
};
