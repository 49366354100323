import React from "react";
import { formatUnits, parseUnits } from "ethers/lib/utils";

const Balance = ({ tokenBalance }) => {
  // const tokenBalance = parseUnits("0.00251");//delete this

  return (
    <div className="w-full text-left mt-2 ml-2">
      <p className="font-poppins font-normal text-white">
        {tokenBalance && (
          <>
            {" "}
            <span className="font-semibold text-white">Balance :</span>
            {formatUnits(tokenBalance || parseUnits("0"))}
          </>
        )}
      </p>
    </div>
  );
};

export default Balance;
