export const chartOptions = {
  chart: {
    animations: { enabled: false },
    toolbar: { show: false },
    width: "100px",
  },
  grid: {
    show: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      dataLabels: {
        position: "top", // top, center, bottom
      },
    },
  },
  tooltip: {
    theme: "dark",
    y: {
      title: {
        formatter: function () {
          return "";
        },
      },
    },
  },
  title: {
    text: "Vote Count",
    align: "center",
    floating: true,
    style: {
      fontSize: "24px",
      colors: ["#ffffff"],
    },
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return val + " votes";
    },
    offsetY: -0,
    style: {
      fontSize: "12px",
      colors: ["#ffffff"],
    },
  },
  xaxis: {
    type: "string",
    labels: {
      show: true,
      style: {
        colors: "#ffffff",
        fontSize: "15px",
        cssClass: "apexcharts-xaxis-label",
      },
    },
  },
  yaxis: {
    labels: {
      show: true,
      minWidth: 0,
      maxWidth: 160,
      style: {
        color: "#ffffff",
        fontSize: "8px",
        cssClass: "apexcharts-yaxis-label",
      },
      offsetX: 0,
      offsetY: 0,
      rotate: 0,
    },
  },
};

export const series = [
  {
    data: [
      {
        x: "Derrick",
        y: 1,
      },
      {
        x: "Nick",
        y: 4,
      },
      {
        x: "Tim",
        y: 5,
      },
      {
        x: "Cephas",
        y: 6,
      },
    ],
  },
];
