import React from "react";
import Loader1 from "../Loader/Loader1";
import { usePools } from "../../hooks";
import { useEthers } from "@usedapp/core";
import Exchange from "../Exchange";
import uniswapLogo from "../../assets/uniswapLogo.png";

const Project4 = () => {
  const [loading, pools] = usePools();

  const { account } = useEthers();

  return (
    <>
      <div className="text-white flex flex-col items-center w-full py-10 text-3xl justify-center">
        <p className="text-center">
          Project 4: Uniswap Clone on Goerli testnet
        </p>
      </div>
      {/* exchange Container*/}
      <div className="flex-1 flex justify-start items-center flex-col w-full mt-10">
        <img
          src={uniswapLogo}
          alt="uniswap logo"
          className="w-56 h-56 object-contain"
        />
        <div className="text-white font-poppins font-black text-5xl tracking-wide">
          Uniswap 2.0
        </div>
        <div className="text-[#E2E2E2] font-poppins font-medium mt-3 text-base">
          Exchange Tokens in seconds
        </div>
        {/* exchange Box Wrapper*/}
        <div className="mt-10 w-full flex justify-center relative">
          {" "}
          {/* Set relative positioning */}
          {/* exchange Box */}
          <div className="relative md:max-w-[700px] md:min-w-[500px] min-w-full max-w-full gradient-border p-[2px] rounded-3xl">
            {/* Blue gradient positioned inside the exchange Box */}
            <div className="absolute bottom-0 right-0 w-60 h-60 rounded-lg bg-gradient-to-br from-blue-500 to-transparent blur-xl z-0" />
            {/* Pink gradient positioned inside the exchange Box */}
            <div className="absolute top-0 left-0 w-60 h-60 rounded-lg bg-gradient-to-br from-pink-500 via-purple-500 to-transparent blur-md" />
            {/* Adjust top, left, width, and height as needed */}
            <div className="w-full min-h-[400px] bg-[#1F1D2B] backdrop-blur-[4px] rounded-3xl shadow-card flex p-10">
              {" "}
              {/* Set relative positioning */}
              {account ? (
                loading ? (
                  <Loader1 title="Loading pools, please wait" />
                ) : (
                  <Exchange pools={pools} />
                )
              ) : (
                <Loader1 title="Please connect your wallet" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Project4;
