import { ethers } from "ethers";
import abi from "../contractJson/uniswapabis/router02.json";

//the factory address returned by getRouterInfo() is the address of the factory contract we compiled and deployed using CRANQ
export const getRouterInfo = async (routerAddress, provider) => {
  const routerContract = new ethers.Contract(routerAddress, abi, provider);

  const factoryAddress = await routerContract.factory();
  return { factory: factoryAddress };
};
