import React from "react";

export const DribbleIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
      >
        <g clipPath="url(#clip0_2_218)">
          <path
            d="M14.0696 11.9465C12.7233 9.55356 11.2842 7.60395 11.1814 7.46436C9.01218 8.48939 7.39374 10.4875 6.88794 12.8967C7.09295 12.9002 10.3345 12.9388 14.0696 11.9465Z"
            fill="#FFFFFF"
          />
          <path
            d="M12.636 6.94678C12.6281 6.95016 12.6202 6.95185 12.6123 6.95354C12.6264 6.95072 12.6349 6.94847 12.6349 6.94847L12.636 6.94678Z"
            fill="#FFFFFF"
          />
          <path
            d="M19.6376 8.66219C18.2668 7.45551 16.4702 6.72192 14.5001 6.72192C13.8682 6.72192 13.2549 6.79902 12.6667 6.94095C12.7836 7.09514 14.2443 9.03599 15.5748 11.4797C18.5103 10.3782 19.6189 8.69256 19.6376 8.66219Z"
            fill="#FFFFFF"
          />
          <path
            d="M15.8247 15.7734C11.2316 17.3738 9.73462 20.6019 9.72119 20.6299C11.04 21.6602 12.697 22.2799 14.5 22.2799C15.5782 22.2799 16.6038 22.0591 17.5372 21.662C17.4221 20.9821 16.9689 18.6003 15.8749 15.7571C15.858 15.7618 15.841 15.767 15.8247 15.7734Z"
            fill="#FFFFFF"
          />
          <path
            d="M15.0385 14.5608C15.1401 14.5275 15.2429 14.4965 15.344 14.4679C15.1501 14.0246 14.9369 13.5819 14.7132 13.145C10.7088 14.3441 6.86453 14.2559 6.72786 14.2536C6.72494 14.3353 6.72144 14.4159 6.72144 14.4989C6.72144 16.4976 7.47546 18.3193 8.71368 19.6976L8.71076 19.693C8.71134 19.6953 10.8403 15.9176 15.0385 14.5608Z"
            fill="#FFFFFF"
          />
          <path
            d="M14.5 0C6.50473 0 0 6.50473 0 14.5C0 22.4947 6.50473 29 14.5 29C22.4947 29 29 22.4947 29 14.5C29 6.50473 22.4958 0 14.5 0ZM23.4233 16.3357C23.3042 16.9209 23.1243 17.4963 22.893 18.0476C22.6635 18.5873 22.382 19.1077 22.0531 19.5948C21.7272 20.0761 21.3528 20.5305 20.9428 20.9428C20.5311 21.354 20.0784 21.7278 19.5948 22.0531C19.1077 22.382 18.5861 22.6646 18.0476 22.893C17.498 23.1249 16.9209 23.3042 16.3357 23.4245C15.7365 23.5477 15.1185 23.6085 14.5012 23.6085C13.8832 23.6085 13.267 23.5477 12.6666 23.4245C12.0814 23.3042 11.5049 23.1249 10.9547 22.893C10.4145 22.6641 9.89406 22.3825 9.40695 22.0531C8.9251 21.726 8.47128 21.354 8.0601 20.9428C7.64775 20.5305 7.27395 20.0784 6.94862 19.5948C6.61979 19.1077 6.33711 18.5855 6.10874 18.0476C5.87686 17.498 5.69697 16.9209 5.57724 16.3357C5.45458 15.737 5.39267 15.1185 5.39267 14.4994C5.39267 13.8821 5.45458 13.2653 5.57724 12.666C5.69697 12.0808 5.87628 11.5049 6.10932 10.9536C6.33769 10.4133 6.61979 9.89348 6.94804 9.40754C7.27453 8.92568 7.64775 8.47128 8.05951 8.0601C8.47128 7.64775 8.92393 7.27395 9.40695 6.94804C9.89406 6.61921 10.415 6.33711 10.9541 6.10815C11.5043 5.87686 12.0814 5.69639 12.666 5.57782C13.2665 5.45458 13.8832 5.39209 14.5006 5.39209C15.1185 5.39209 15.7353 5.45458 16.3357 5.57782C16.9209 5.69639 17.4963 5.87628 18.0476 6.10815C18.5885 6.33711 19.1077 6.61804 19.5948 6.94804C20.0761 7.27453 20.5311 7.64833 20.9428 8.0601C21.354 8.47186 21.7284 8.9251 22.0531 9.40754C22.382 9.89406 22.6646 10.4145 22.893 10.9536C23.1243 11.5043 23.3048 12.0808 23.4239 12.666C23.5472 13.2647 23.6079 13.8821 23.6079 14.4994C23.6079 15.1185 23.546 15.7347 23.4233 16.3357Z"
            fill="#FFFFFF"
          />
          <path
            d="M17.3328 15.3961C18.3537 18.1996 18.7678 20.4828 18.8467 20.9529C20.5942 19.7725 21.8342 17.9029 22.1811 15.7355C22.0199 15.6823 19.8437 14.9955 17.3328 15.3961Z"
            fill="#FFFFFF"
          />
          <path
            d="M16.1721 12.6373C16.3543 13.0081 16.5272 13.3849 16.6902 13.7657C16.7486 13.9 16.8023 14.0343 16.8578 14.1675C19.5305 13.8323 22.1635 14.3988 22.2744 14.4227C22.2581 12.5765 21.5969 10.8839 20.5071 9.55981C20.4936 9.58026 19.2455 11.3838 16.1721 12.6373Z"
            fill="#FFFFFF"
          />
        </g>
        <defs>
          <clipPath id="clip0_2_218">
            <rect width="29" height="29" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export const InstaIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="29"
        viewBox="0 0 30 29"
        fill="none"
      >
        <g clipPath="url(#clip0_2_230)">
          <path
            d="M14.9993 17.4028C16.599 17.4028 17.9033 16.1009 17.9033 14.5C17.9033 13.868 17.6965 13.2845 17.3542 12.8079C16.8268 12.0767 15.9694 11.5972 15.001 11.5972C14.0321 11.5972 13.1753 12.0761 12.6467 12.8074C12.3032 13.2839 12.0982 13.8674 12.0977 14.4994C12.0959 16.1003 13.399 17.4028 14.9993 17.4028Z"
            fill="#FFFFFF"
          />
          <path
            d="M21.3382 10.9489V8.51568V8.15356L20.9743 8.15473L18.5422 8.16233L18.5516 10.9582L21.3382 10.9489Z"
            fill="#FFFFFF"
          />
          <path
            d="M15 0C7.00473 0 0.5 6.50473 0.5 14.5C0.5 22.4947 7.00473 29 15 29C22.9947 29 29.5 22.4947 29.5 14.5C29.5 6.50473 22.9958 0 15 0ZM23.2464 12.808V19.5598C23.2464 21.3184 21.8166 22.7476 20.0592 22.7476H9.94083C8.18279 22.7476 6.75358 21.3184 6.75358 19.5598V12.808V9.44141C6.75358 7.68338 8.18279 6.25417 9.94083 6.25417H20.0586C21.8166 6.25417 23.2464 7.68338 23.2464 9.44141V12.808Z"
            fill="#FFFFFF"
          />
          <path
            d="M19.5108 14.4999C19.5108 16.9863 17.4876 19.0106 15.0001 19.0106C12.5126 19.0106 10.4899 16.9863 10.4899 14.4999C10.4899 13.9018 10.6091 13.33 10.8217 12.8079H8.35986V19.5597C8.35986 20.4322 9.06833 21.139 9.94034 21.139H20.0581C20.9289 21.139 21.6386 20.4322 21.6386 19.5597V12.8079H19.1756C19.3899 13.33 19.5108 13.9018 19.5108 14.4999Z"
            fill="#FFFFFF"
          />
        </g>
        <defs>
          <clipPath id="clip0_2_230">
            <rect
              width="29"
              height="29"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export const TwitterIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="29"
        viewBox="0 0 30 29"
        fill="none"
      >
        <g clipPath="url(#clip0_2_246)">
          <path
            d="M15 0C7.00473 0 0.5 6.50473 0.5 14.5C0.5 22.4947 7.00473 29 15 29C22.9947 29 29.5 22.4947 29.5 14.5C29.5 6.50473 22.9958 0 15 0ZM21.4685 11.1813C21.4749 11.325 21.4784 11.4699 21.4784 11.6147C21.4784 16.025 18.1224 21.1087 11.9821 21.1087C10.0974 21.1087 8.34282 20.5579 6.86631 19.6106C7.12739 19.6415 7.39314 19.6573 7.66239 19.6573C9.22651 19.6573 10.6651 19.1235 11.8075 18.2287C10.3473 18.2018 9.11438 17.2369 8.68976 15.9105C8.89301 15.9491 9.10269 15.9707 9.31705 15.9707C9.62134 15.9707 9.91688 15.931 10.1966 15.8544C8.6699 15.5484 7.51988 14.1998 7.51988 12.5819C7.51988 12.5679 7.51988 12.5533 7.52046 12.5399C7.97019 12.7893 8.48475 12.94 9.03144 12.9569C8.13665 12.3594 7.54733 11.3373 7.54733 10.1797C7.54733 9.56757 7.71145 8.99402 7.99881 8.50107C9.64412 10.5208 12.1042 11.8489 14.8773 11.9891C14.8201 11.7444 14.7915 11.4903 14.7915 11.2281C14.7915 9.38534 16.2855 7.89072 18.1283 7.89072C19.0885 7.89072 19.9546 8.29606 20.5644 8.94437C21.3254 8.79485 22.038 8.518 22.6845 8.13427C22.4334 8.914 21.906 9.56757 21.215 9.98109C21.8908 9.90049 22.5356 9.72176 23.1319 9.45601C22.6869 10.1242 22.1209 10.7123 21.4685 11.1813Z"
            fill="#FFFFFF"
          />
        </g>
        <defs>
          <clipPath id="clip0_2_246">
            <rect
              width="29"
              height="29"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export const FacebookIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
      >
        <g clipPath="url(#clip0_2_250)">
          <path
            d="M14.5 0C6.50473 0 0 6.50473 0 14.5C0 22.4947 6.50473 29 14.5 29C22.4947 29 29 22.4947 29 14.5C29 6.50473 22.4958 0 14.5 0ZM18.106 15.0105H15.747V23.4187H12.2513C12.2513 23.4187 12.2513 18.8244 12.2513 15.0105H10.5897V12.0387H12.2513V10.1166C12.2513 8.73995 12.9055 6.58884 15.7791 6.58884L18.3694 6.59877V9.48346C18.3694 9.48346 16.7954 9.48346 16.4893 9.48346C16.1833 9.48346 15.7481 9.63649 15.7481 10.293V12.0393H18.4115L18.106 15.0105Z"
            fill="#FFFFFF"
          />
        </g>
        <defs>
          <clipPath id="clip0_2_250">
            <rect width="29" height="29" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
