import React from "react";
import { SiEthereum } from "react-icons/si";
import { BsInfoCircle } from "react-icons/bs";
import { shortenAddress } from "../utils/shortenAddress";

const Hero = () => {
  const currentAccount = "placeholder";
  return (
    <>
      <div className="flex flex-col justify-center items-center w-full py-[0.8rem] px-[2rem] ">
        {/* Identification card*/}
        <div className="p-3 flex justify-center items-center flex-col rounded-xl h-40 sm:w-72 w-full my-5 eth-card .white-glassmorphism ">
          <div className="flex justify-between flex-col w-full h-full">
            <div className="flex justify-between items-start">
              <div className="w-10 h-10 rounded-full border-2 border-white flex justify-center items-center">
                <SiEthereum fontSize={21} color="#fff" />
              </div>
              <BsInfoCircle fontSize={17} color="#fff" />
            </div>
            <div>
              <p className="text-white font-light text-sm">codechain.me</p>
              <p className="text-white font-light text-sm">
                {shortenAddress(currentAccount)}
              </p>
              <p className="text-white font-semibold text-lg mt-1">Ethereum</p>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col gap-[1rem] mb-6">
          <h1 className="text-display text-center text-white text-3xl">
            About Me
          </h1>
          <p className="text-base text-white-125 text-center text-white">
            Hello my name is Tim. I aspire to be a<strong> Full Stack </strong>
            Blockchain developer. <br /> I want to
            <strong> build </strong>blockchain applications that will improve
            the way we live. <br /> Recently I quit my finance job to pursue
            this full time. <br />
            My goal is to get a job in 6 months to 1 year time.
          </p>
        </div>

        {/* Grey line */}
        <div className="w-full bg-gray-400 h-[0.25px] mt-4" />
      </div>
    </>
  );
};

export default Hero;
