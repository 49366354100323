import { ethers } from "ethers";
import TextInput from "../ui/TextInput";
import React, { useState, useEffect } from "react";
import { shortenAddress } from "../../utils/shortenAddress";

const Buy = ({ state, setAccount, account }) => {
  const [postMessage, setPostMessage] = useState({
    name: "",
    message: "",
  });

  useEffect(() => {
    setAccount(account); // Set the account state when 'account' changes
  }, []);

  // console.log("state.contract", state.contract);
  // console.log(!state.contract);
  // console.log(account);

  const buyChai = async (event) => {
    event.preventDefault();
    const { contract } = state;

    // const name = document.querySelector("#name").value;
    // const message = document.querySelector("#message").value;

    //const amount = document.querySelector("#amount").value;
    const amount = { value: ethers.utils.parseEther("0.001") };

    const transaction = await contract.buyChai(
      postMessage.name,
      postMessage.message,
      amount
    );
    await transaction.wait();
    alert("Transaction is successul");
    window.location.reload();
  };
  return (
    <div
      className="flex flex-col text-center mt-5 border border-[#808080] justify-center p-4 rounded-lg"
      style={{
        boxShadow: "0 0 0 0 white, 0 0 10px white",
        transition: "box-shadow 0.3s ease",
      }}
    >
      <h3 className="text-sm">Input form below</h3>
      {account ? (
        <h3 className="text-sm">
          Connected Account - {shortenAddress(account[0])}
        </h3>
      ) : null}
      <form
        onSubmit={buyChai}
        className="flex flex-col gap-4 justify-center items-center"
      >
        <TextInput
          styles="w-full rounded-full py-5 pr-14" // Adjust padding for the input and button spacing
          placeholder="name"
          type="text"
          name="name"
          required=""
          onChange={(e) =>
            setPostMessage({ ...postMessage, name: e.target.value })
          }
        />
        <TextInput
          styles="w-full rounded-full py-5 pr-14" // Adjust padding for the input and button spacing
          placeholder="message"
          type="text"
          name="message"
          required=""
          onChange={(e) =>
            setPostMessage({ ...postMessage, message: e.target.value })
          }
        />
        <div className="gap-4">
          <button
            type="submit"
            className="flex flex-row justify-center items-center bg-[#2952e3] p-1 rounded-full cursor-pointer hover:bg-[#87a0fa]"
            disabled={!state.contract}
          >
            <p className="text-white text-base font-semibold p-2">Pay</p>
          </button>
        </div>
      </form>
    </div>
  );
};
export default Buy;
