import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { chartOptions } from "./votechart.config";

const Votechart = ({ state }) => {
  const [cand, setCand] = useState([]);
  const { contract } = state;

  const buildGraphData = (cand) => {
    return cand.map((item) => {
      // Convert BigInt to string and then parse it to a regular number
      const voteCountNumber = Number(String(item.voteCount));
      return { x: item.name, y: voteCountNumber };
    });
  };

  const voteChartSelector = (cand) => {
    return {
      series: [
        {
          data: buildGraphData(cand),
        },
      ],
    };
  };

  useEffect(() => {
    const listCand = async () => {
      if (contract) {
        const fetchedCandidates = await contract.getAllVotesOfCandidates();
        setCand(fetchedCandidates);
      }
    };
    listCand();
  }, [contract]);

  // console.log(cand);

  const voteChartseries = voteChartSelector(cand);
  // console.log("voteChartseries", voteChartseries.series);

  return (
    <div className="text-white flex flex-col items-center w-full py-10 text-3xl justify-center">
      <h3 className="text-sm">Step 3: Display Results</h3>
      <Chart
        options={chartOptions}
        series={voteChartseries.series}
        type="bar"
        height={380}
      />
    </div>
  );
};

export default Votechart;
