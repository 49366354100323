import { ethers } from "ethers";
import pairAbi from "../contractJson/uniswapabis/pair.json";
import erc20Abi from "../contractJson/uniswapabis/erc20.json";

export const getPairsInfo = async (pairAddresses, provider) => {
  const pairsInfo = [];
  const pairABI = pairAbi;
  const tokenABI = erc20Abi.abi;

  for (let i = 0; i < pairAddresses.length; i++) {
    const pairAddress = pairAddresses[i];
    const pair = new ethers.Contract(pairAddress, pairABI, provider);

    const token0Address = await pair.token0();
    const token1Address = await pair.token1();

    const token0Contract = new ethers.Contract(
      token0Address,
      tokenABI,
      provider
    );
    const token1Contract = new ethers.Contract(
      token1Address,
      tokenABI,
      provider
    );

    const token0Name = await token0Contract.name();
    const token1Name = await token1Contract.name();

    pairsInfo.push({
      address: pairAddress,
      token0Address,
      token1Address,
      token0Name,
      token1Name,
    });
  }
  return pairsInfo;
};
