import React from "react";
import { CandidateContextProvider } from "../contexts/candidateContext";
import { DAppProvider } from "@usedapp/core";
import { DAPP_CONFIG } from "../config";

import { Project1, Project2, Project3, Project4 } from "./projects/index.js";

const Projects = () => {
  return (
    <>
      <Project1 />
      <Project2 />
      <CandidateContextProvider>
        <Project3 />
      </CandidateContextProvider>
      <DAppProvider config={DAPP_CONFIG}>
        <Project4 />
      </DAppProvider>
    </>
  );
};

export default Projects;
