import React from "react";
import Projects from "../components/Projects";
import NavBar from "../components/NavBar";
import Hero from "../components/Hero";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <div className="w-full px-0 lg:px-10 pb-5 md:pb-20 2xl:px-40 min-h-screen gradient-bg-welcome ">
      <NavBar />
      <Hero />
      <Projects />
      <Footer />
    </div>
  );
};

export default Home;
