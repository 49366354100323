import React, { useState, useEffect } from "react";
import {
  ERC20,
  useContractFunction,
  useEthers,
  useTokenAllowance,
  useTokenBalance,
} from "@usedapp/core";
import abi from "../contractJson/uniswapabis/router02.json";
import AmountIn from "./ui/AmountIn";
import AmountOut from "./ui/AmountOut";
import Balance from "./ui/Balance";
import { Radio, Popover } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { parseUnits } from "ethers/lib/utils";
import {
  getAvailableTokens,
  getCounterpartTokens,
  findPoolByTokens,
  isOperationPending,
  getFailureMessage,
  getSuccessMessage,
  useAmountsOut,
} from "../utils";
import { ROUTER_ADDRESS } from "../config";
import { ethers } from "ethers";

const Exchange = ({ pools }) => {
  console.log("pools", pools);
  const { account } = useEthers();

  const { ethereum } = window;
  const provider = new ethers.providers.Web3Provider(ethereum); //read the Blockchain
  const signer = provider.getSigner(); //write the blockchain
  const routerContract = new ethers.Contract(ROUTER_ADDRESS, abi, signer);

  const [slippage, setSlipppage] = useState(2.5);
  const handleSlippageChange = (e) => {
    setSlipppage(e.target.value);
  };

  const settings = (
    <>
      <div>Slippage Tolerance</div>
      <div>
        <Radio.Group value={slippage} onChange={handleSlippageChange} />
        <Radio.Button value={0.5}>0.5%</Radio.Button>
        <Radio.Button value={2.5}>2.5%</Radio.Button>
        <Radio.Button value={5.0}>5.0%</Radio.Button>
      </div>
    </>
  );

  const [toToken, setToToken] = useState("");
  const toTokenBalance = useTokenBalance(toToken, account); //parseUnits("0.00251");
  const [resetState, setResetState] = useState(false);

  //Amount In/Out Inputs
  const [fromValue, setFromValue] = useState("0");
  const onFromValueChange = (value) => {
    const trimmedValue = value.trim();
    try {
      if (trimmedValue) {
        parseUnits(value);
        setFromValue(value);
        console.log("value", value);
      } else {
        setFromValue("0");
        console.log("value cleared");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [fromToken, setFromToken] = useState(pools[0].token0Address);
  const fromTokenContract = new ethers.Contract(fromToken, ERC20.abi, signer);
  const fromTokenBalance = useTokenBalance(fromToken, account); //for getting the balance
  const availableTokens = getAvailableTokens(pools);
  console.log(availableTokens);
  const onFromTokenChange = (value) => {
    setFromToken(value);
  };

  const { state: swapExecuteState, send: swapExecuteSend } =
    useContractFunction(routerContract, "swapExactTokensForTokens", {
      transactionName: "swapExactTokensForTokens",
      gasLimitBufferPercentage: 10,
    });
  const isSwapping = isOperationPending(swapExecuteState);
  const fromValueBigNumber = parseUnits(fromValue);
  //.lte() method is used to check whether a BigNumber value is less than or equal to another BigNumber value
  const hasEnoughBalance = fromValueBigNumber.lte(
    fromTokenBalance ?? parseUnits("0")
  );
  const fromValueIsGreatThan0 = fromValueBigNumber.gt(parseUnits("0"));

  const { state: swapApproveState, send: swapApproveSend } =
    useContractFunction(fromTokenContract, "approve", {
      transactionName: "onApproveRequested",
      gasLimitBufferPercentage: 10,
    });
  const isApproving = isOperationPending(swapApproveState);

  const tokenAllowance =
    useTokenAllowance(fromToken, account, ROUTER_ADDRESS) || parseUnits("0");

  console.log(parseInt(tokenAllowance));
  console.log(parseInt(fromValueBigNumber));
  // boolean values - comparison checks if the value represented by fromValueBigNumber is greater than the value represented by tokenAllowance.
  const approvedNeeded = fromValueBigNumber.gt(tokenAllowance);
  console.log(approvedNeeded);
  const canApprove = !isApproving && approvedNeeded;

  //Setting the allowance to MaxUint256 effectively approves the maximum amount of tokens that can be spent by another contract or address, allowing unlimited spending permissions for the specified token to the specified address (ROUTER_ADDRESS). This is a common pattern used in decentralized exchanges (DEXs) or other token-related scenarios where you want to approve a contract to spend tokens without any limitations on the amount.
  const onApproveRequested = () => {
    swapApproveSend(ROUTER_ADDRESS, ethers.constants.MaxUint256); //ethers.constants.MaxUint256 is a predefined constant provided by the ethers.js library.
  };

  //Amount Out inputs
  const pairAddress =
    findPoolByTokens(pools, fromToken, toToken)?.address ?? ""; //address is the contract address of the paired contract between 2 tokens
  const onToTokenChange = (value) => {
    setToToken(value);
  };
  const counterpartTokens = getCounterpartTokens(pools, fromToken);
  console.log("counterpart tokens", counterpartTokens);

  //UNISWAP documentation
  // function swapExactTokensForTokens(
  //   uint amountIn,
  //   uint amountOutMin,
  //   address[] calldata path,
  //   address to,
  //   uint deadline
  // ) external returns (uint[] memory amounts);

  //  https://docs.uniswap.org/protocol/V2/reference/smart-contracts/router-02#swapexacttokensfortokens

  const amountOut =
    useAmountsOut(pairAddress, fromValueBigNumber, fromToken, toToken) ?? "0";

  const amountOutMin = amountOut * (1 - slippage / 100);

  // Assuming parseUnits expects a string representation, parse amountOutMinString to the appropriate format
  const amountOutMinBigNumber = BigInt(amountOutMin.toString());

  console.log(parseInt(amountOutMinBigNumber));

  const onSwapRequested = () => {
    swapExecuteSend(
      fromValueBigNumber,
      amountOutMinBigNumber,
      [fromToken, toToken],
      account,
      Math.floor(Date.now() / 1000) + 60 * 2 //2minutes
    ).then(() => {
      setFromValue("0");
    });
  };

  console.log(approvedNeeded);
  console.log(isSwapping);
  console.log(fromValueIsGreatThan0);
  console.log(hasEnoughBalance);

  //!approvedNeeded, !isSwapping, fromValueIsGreatThan0, hasEnoughBalance
  const canSwap =
    !approvedNeeded && !isSwapping && fromValueIsGreatThan0 && hasEnoughBalance;

  const successMessage = getSuccessMessage(swapApproveState, swapExecuteState);
  const failureMessage = getFailureMessage(swapApproveState, swapExecuteState);

  useEffect(() => {
    if (failureMessage || successMessage) {
      setTimeout(() => {
        setResetState(true);
        setFromValue("0");
        setToToken("");
      }, 5000);
    }
  }, [failureMessage, successMessage]);

  return (
    <div className="flex flex-col w-full items-center text-white ">
      <Popover
        content={settings}
        title="Settings"
        trigger="click"
        placement="bottomRight"
      >
        <SettingOutlined className="cog absolute top-5 right-5 mt-2 mr-2" />
      </Popover>
      <div className="mb-8">
        <AmountIn
          value={fromValue}
          onChange={onFromValueChange}
          currencyValue={fromToken}
          currencies={availableTokens}
          onSelect={onFromTokenChange}
          isSwapping={isSwapping && hasEnoughBalance}
        />
        <Balance tokenBalance={fromTokenBalance} />
      </div>
      <div className="mb-8 w-[100%]">
        <AmountOut
          fromToken={fromToken}
          toToken={toToken}
          amountIn={fromValueBigNumber}
          pairContract={pairAddress}
          currencyValue={toToken}
          onSelect={onToTokenChange}
          currencies={counterpartTokens}
        />
        <Balance tokenBalance={toTokenBalance} />
      </div>

      {approvedNeeded && !isSwapping ? (
        //approval button
        <button
          disabled={!canApprove}
          onClick={onApproveRequested}
          className={`${
            canApprove
              ? "bg-[#E8006F] text-white"
              : "bg-[#ffffff05] text-[#ffffff50]"
          } border-none outline-none px-6 py-2 font-poppins font-bold text-lg rounded-2xl leading-[24px] transition-all min-h-[56px]`}
        >
          {isApproving ? "Approving..." : "Approve"}
        </button>
      ) : (
        //swap button
        <button
          disabled={!canSwap}
          onClick={onSwapRequested}
          className={`${
            canSwap
              ? "bg-[#E8006F] text-white"
              : "bg-[#ffffff05] text-[#ffffff50]"
          } border-none outline-none px-6 py-2 font-poppins font-bold text-lg rounded-2xl leading-[24px] transition-all min-h-[56px]`}
        >
          {isSwapping
            ? "Swapping..."
            : hasEnoughBalance
            ? "Swap"
            : "Insufficient balance"}
        </button>
      )}
      {failureMessage && !resetState ? (
        <p className="font-poppins font-lg text-white font-bold mt-7">
          {failureMessage}
        </p>
      ) : successMessage ? (
        <p className="font-poppins font-lg text-white font-bold mt-7">
          {successMessage}
        </p>
      ) : (
        ""
      )}
    </div>
  );
};

export default Exchange;
