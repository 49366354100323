import React from "react";
import { DribbleIcon, InstaIcon, TwitterIcon, FacebookIcon } from "./ui/icons";

const Footer = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center w-full px-5">
        <div className="grid grid-cols-2 gap-2 w-full">
          <div className="flex flex-col justify-between my-4 px-4 gap-2">
            <p className="text-white">Follow me on</p>
            <div className="flex gap-[1rem]">
              <DribbleIcon />
              <InstaIcon />
              <TwitterIcon />
              <FacebookIcon />
            </div>
          </div>
          {/* Email */}
          <div className="px-10 w-full flex sm:flex-row flex-col justify-end items-end my-4">
            <p className="text-sm text-white items-center">
              hello@codechain.me
            </p>
          </div>
        </div>

        {/* Grey line */}
        <div className="w-full bg-gray-400 h-[0.25px] mt-4" />
        {/* Footer text */}
        <div className="px-5 w-full flex justify-between mt-3">
          <p className="text-white  text-xs">@TKcodechain2023</p>
          <p className="text-white  text-xs">All rights reserved</p>
        </div>
      </div>
    </>
  );
};
export default Footer;
