import React, { useState, useEffect } from "react";
import abi from "../../contractJson/chai.json";
import { ethers } from "ethers";
import Buy from "../forms/Buy";
import Memo from "../tables/Memo";

const Project2 = () => {
  const [state, setState] = useState({
    provider: null,
    signer: null,
    contract: null,
  });
  const [account, setAccount] = useState("Not connected");
  useEffect(() => {
    const template = async () => {
      const contractAddress = "0xa64e3144835aF8781c750ceC432784a68d883266";
      const contractABI = abi.abi;
      //Metamask part
      //1. In order do transactions on goerli testnet
      //2. Metmask consists of infura api which actually help in connecting to the blockchain
      try {
        const { ethereum } = window;
        const account = await ethereum.request({
          method: "eth_requestAccounts",
        });

        window.ethereum.on("accountsChanged", () => {
          window.location.reload();
        });
        setAccount(account);
        const provider = new ethers.providers.Web3Provider(ethereum); //read the Blockchain
        const signer = provider.getSigner(); //write the blockchain

        const contract = new ethers.Contract(
          contractAddress,
          contractABI,
          signer
        );
        // console.log(contract);
        setState({ provider, signer, contract });
      } catch (error) {
        console.log(error);
      }
    };
    template();
  }, []);
  return (
    <div className="text-white flex flex-col items-center w-full py-10 text-3xl justify-center">
      <p className="text-center">
        Project 2: Send message to blockchain Goerli testnet
      </p>
      <Buy state={state} setAccount={setAccount} account={account} />
      <Memo state={state} />
      {/* Grey line */}
      <div className="w-full bg-gray-400 h-[0.25px] mt-4" />
    </div>
  );
};

export default Project2;
