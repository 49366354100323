import React, { useState, useEffect } from "react";
import VoteCard from "../ui/VoteCard";

const Listcandidates = ({ state }) => {
  const { contract } = state;

  const [cand, setCand] = useState([]);

  useEffect(() => {
    const listCand = async () => {
      if (contract) {
        const fetchedCandidates = await contract.getAllVotesOfCandidates();
        setCand(fetchedCandidates);
      }
    };
    listCand();
  }, [contract]);

  // console.log(cand);

  return (
    <div className="flex flex-col items-center justify-center ">
      <h3 className="text-sm">Step 2: List and Vote for Candidates</h3>
      <div className="w-[0.5] justify-between grid lg:grid-cols-3 md:grid-col-2 sm: grid-cols-2 gap-4 p-10 items-center text-center">
        {cand?.length > 0
          ? cand.map((cand, i) => {
              return (
                <VoteCard
                  key={i}
                  index={i}
                  name={cand.name}
                  voteCount={cand.voteCount.toNumber()}
                />
              );
            })
          : null}
      </div>
    </div>
  );
};

export default Listcandidates;
