import React, { useState, useEffect } from "react";
import { shortenAddress } from "../../utils/shortenAddress";

const Memos = ({ state }) => {
  const [memos, setMemos] = useState([]);
  const { contract } = state;

  useEffect(() => {
    const memosMessage = async () => {
      const fetchedMemos = await contract.getMemos();
      const recentMemos = fetchedMemos.slice(-5).reverse();
      setMemos(recentMemos);
      //console.log(memos)
    };
    contract && memosMessage();
  }, [contract]);

  return (
    <div className="relative overflow-x-auto items-center text-center w-full justify-center">
      <h3 className="my-3">Messages</h3>
      <table
        className="md:w-full lg:w-[80%] text-sm text-left text-[#aeacac] border rounded-full"
        style={{ margin: "0 auto" }}
      >
        <thead className="text-l text-gray-700 uppercase bg-gray-50 border-b">
          <tr>
            <th scope="col" className="px-6 py-3">
              Name
            </th>
            <th scope="col" className="px-6 py-3">
              Timestamp
            </th>
            <th scope="col" className="px-6 py-3">
              Message
            </th>
            <th scope="col" className="px-6 py-3">
              user address
            </th>
          </tr>
        </thead>
        <tbody>
          {memos.map((memo, i) => {
            return (
              <tr className=" border-b" key={i}>
                <td
                  style={{
                    border: "1px solid white",
                    borderCollapse: "collapse",
                    padding: "7px",
                    width: "25%",
                    color: "white",
                  }}
                >
                  {memo.name}
                </td>
                <td
                  style={{
                    border: "1px solid white",
                    borderCollapse: "collapse",
                    padding: "7px",
                    width: "25%",
                    color: "white",
                  }}
                >
                  {new Date(memo.timestamp * 1000).toLocaleString()}
                </td>
                <td
                  style={{
                    border: "1px solid white",
                    borderCollapse: "collapse",
                    padding: "7px",
                    width: "25%",
                    color: "white",
                  }}
                >
                  {memo.message}
                </td>
                <td
                  className="container-fluid"
                  style={{
                    border: "1px solid white",
                    borderCollapse: "collapse",
                    padding: "7px",
                    width: "25%",
                    color: "white",
                  }}
                >
                  {shortenAddress(memo.from)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default Memos;
