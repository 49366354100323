import React, { useContext, useState } from "react";
import { CandidateContext } from "../../contexts/candidateContext";
import Loader from "../Loader/Loader";

export default function Card({ name, voteCount, index }) {
  const { state } = useContext(CandidateContext);
  const { contract } = state;
  const [success, setSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const addVote = async (e) => {
    e.preventDefault();
    setLoading(true);
    const tx = await contract.vote(index);
    await tx.wait();
    setSuccess(true);
    setLoading(false);
    setTimeout(() => {
      window.location.reload();
    }, "10 seconds");
  };

  return (
    <div
      className="flex flex-col gap-[2] p-[2rem] rounded-[1rem] justify-center max-w-[20rem] items-center border"
      style={{
        boxShadow: "0 0 0 0 white, 0 0 10px white",
        transition: "box-shadow 0.3s ease",
      }}
    >
      <h4 className="text-white"> {name}</h4>
      <h5 className="text-white text-sm mt-3">vote Count : {voteCount}</h5>
      {isLoading ? (
        <Loader />
      ) : (
        <button
          type="button"
          className="flex flex-row justify-center items-center bg-[#2952e3] p-1 rounded-full cursor-pointer hover:bg-[#87a0fa] mt-2"
          onClick={addVote}
        >
          <p className="text-white text-base font-semibold p-2">Vote</p>
        </button>
      )}
      {success ? (
        <p className="text-[#14A44D] text-base">Successfully voted!</p>
      ) : null}
    </div>
  );
}
